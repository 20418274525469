"use client"

import useSWR from "swr"

function getValueByPath(json, path) {
  const parts = path.split('>');
  let current = json;
  
  for (let part of parts) {
    if (part.includes('[')) {
      const [key, index] = part.split('[')
      const numericIndex = parseInt(index.replace(']', ''))
      current = current[key].filter((_, i) => i === numericIndex)
    } else {
      current = current[part.trim()]
    }
  }
  
  return current
}

const fetcher = (path, format) => (...args) => fetch(...args).then(res => res.json()).then(json => format(getValueByPath(json, path)))

export default function FetchPrice({ source, path, format }) {
  const { data } = useSWR(source, fetcher(path, format), { refreshInterval: 3600000 })
  
  return typeof data !== "undefined" ? data : "…"
}