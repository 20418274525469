import './App.css';
import FetchPrice from './FetchPrice';
import BgImg from "./assets/bg.jpg"
import Dps from "./assets/dps.png"
import Firechain from "./assets/firechain.png"
import Yedinet from "./assets/yedinet.svg"

const NumberFormat = ({ number }) => {
  const formattedNumber = new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: 'USD' 
  }).format(number);

  // Extract the integer part and decimal part using regex
  const regex = /^(\D*)(\d[\d,]*)\.(\d*)$/;
  const [, currencySymbol, integerPart, decimalPart] = formattedNumber.match(regex);

  return (
    <span className="pricetag">
      {currencySymbol}
      <span style={{ fontWeight: 'bold' }}>{integerPart}</span>
      {`.${decimalPart}`}
    </span>
  );
}

function App() {
  const domain = new URL(window.location.href).hostname;
  return (
    <div id="top" className="page" style={{ backgroundImage: `url(${BgImg})` }}>
      <header className="header">
        <div className="separator-under">
          <ul className="prices">
          <li>
              BTC: <FetchPrice source="https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=BTC,USD" path="USD" format={v => <NumberFormat number={v} />} />
            </li>
            <li>
              ETH: <FetchPrice source="https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=BTC,USD" path="USD" format={v => <NumberFormat number={v} />} />
            </li>
            <li>
              BNB: <FetchPrice source="https://oracle-api.yedi.net/price?chain=bnb&contract=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" path="price" format={v => <NumberFormat number={v} />} />
            </li>
          </ul>  
        </div>
        <nav className="nav">
          <span className="website-domain">{domain}</span>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://yedi.net/discover">Tokens</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://app.coinzilla.com/marketplace/listing/800638877a37f709956/">Advertise</a></li>
            <li><a href={`mailto:ben@moonscan.com?subject=${domain}%20interest`}>Purchase</a></li>
          </ul>
        </nav>
      </header>
      <content>
        <section className="teaser">
          <b>{domain}</b> is for sale
          <a className="button" href={`mailto:ben@moonscan.com?subject=${domain}%20interest`}>Inquiry</a>
        </section>
        <div className="cols">
          <section>
            <div className="title">Featured Projects</div>
            <div className="separator separator-under" />
            <ul className="list">
              <li onClick={() => window.open("https://thefirechain.com", "_blank", "noopener")}>
                <span className="icon">
                  <img alt="Firechain" src={Firechain} />
                </span>
                <span className="col full">
                  <span>FIRECHAIN</span>
                  <span className="small">just now</span>
                </span>
                <span className="col">
                  <span className="small">The Firechain Network</span>
                </span>
                <span className="col">
                  <span className="dex">DEX</span>
                </span>
              </li>
              <li onClick={() => window.open("https://deepspace.game", "_blank", "noopener")}>
                <span className="icon">
                  <img alt="DEEPSPACE" src={Dps} />
                </span>
                <span className="col full">
                  <span>DEEPSPACE</span>
                  <span className="small">just now</span>
                </span>
                <span className="col">
                  <span className="small">Space-based WEB3 strategy exploration</span>
                </span>
                <span className="col">
                  <span className="game">Game</span>
                </span>
              </li>
            </ul>
          </section>
          <section>
            <div className="title">Yedi Network</div>
            <div className="separator separator-under" />
            <ul className="list">
              <li onClick={() => window.open("https://yedi.net/discover", "_blank", "noopener")}>
                <span className="icon">
                  <img alt="Yedi Network" src={Yedinet} />
                </span>
                <span className="col full">
                  <span>Discover Tokens</span>
                  <span className="small">just now</span>
                </span>
                <span className="col">
                  <span className="small">Find and explore new tokens.</span>
                </span>
                <span className="col">
                  <span className="tool">Tool</span>
                </span>
              </li>
              <li onClick={() => window.open("https://yedi.net/opportuna", "_blank", "noopener")}>
                <span className="icon">
                  <img alt="Yedi Network" src={Yedinet} />
                </span>
                <span className="col full">
                  <span>Job Opportunities</span>
                  <span className="small">just now</span>
                </span>
                <span className="col">
                  <span className="small">Find a crypto job.</span>
                </span>
                <span className="col">
                  <span className="tool">Tool</span>
                </span>
              </li>
            </ul>
          </section>
        </div>
      </content>
      <footer>
        <div className="social separator-under">
          <span></span>
          <span><a href="#top">Back to Top</a></span>
        </div>
        <div className="under">
          <div className="flex separator-under">
            <div class="first">
              <div className="col">
                <strong>Powered By Yedi Network</strong>
                <span>Yedi Network is the successor of the reflection tracking platform which was running under the domain moonscan.com</span>
              </div>
              <div>
                <div className="menu-title">Yedi Network</div>
                <ul>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://yedi.net/discover">Tokens</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://yedi.net/opportuna">Jobs</a></li>
                </ul>
              </div>
            </div>
            <div className="others">
              <div>
                <div className="menu-title">Community</div>
                <ul>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://t.me/YediNetwork">Telegram</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://x.com/YediNetwork">X (formerly Twitter)</a></li>
                </ul>
              </div>
              <div>
                <div className="menu-title">Products &amp; Services</div>
                <ul>
                  <li><a href={`mailto:ben@moonscan.com?subject=${domain}%20interest`}>Inquiry</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="end">
            <a target="_blank" rel="noopener noreferrer" href="https://yedi.net">Yedi Network</a>
            <span>Donations: <a target="_blank" rel="noopener noreferrer" href="https://bscscan.com/address/0x2F17985F97640c5FCa8531F5dEe8CAf2A5B89588">0x2F17…9588</a></span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
